import { urls } from '../../referenceData';
import {fetchWithRetry, getAppData} from "../index";

let loadingPromise;
let output;

export default async () => {
  if (loadingPromise) {
    await loadingPromise;
  }

  if (output) {
    return output;
  } else {

    let resolveLoadingPromise;
    loadingPromise = new Promise(function (resolve, reject) {
      resolveLoadingPromise = resolve;
    });

    const res = await fetchWithRetry(`${urls.apiUrl()}publicviews?_v=${process.env.VERSION}`);// eslint-disable-line i18next/no-literal-string

    const toJson = await res.json();

    const most = await getAppData();
    const measures = most.measures;

    toJson.map((view, key) => {
      const meas = [];

      view.meas.split(/[-|]/).map((measure) => {

        // if it starts with underscore, it's special - it means all meas at that level
        if (measure.startsWith('_')) {
          const level = parseInt(measure.replace('_', ''), 10);

          measures.map((m) => {
            if (m.lvl === level) {
              meas.push(m.s_id);
            }
          });
        } else if (measure.startsWith('.')) {
          // no op; this was old citizens voices shorthand
        } else {
          meas.push(measure);
        }
      });

      // now for each measure, check lvl <= 2, and if it's not, walk up
      // the parent tree via meas.p_sid until we find a lvl <= 2
      view.meas = [];
      meas.map((m) => {
        let measure = m;
        while (measure) {
          const mObj = measures.find((meas) => meas.s_id === measure);
          if (!mObj) {
            // this happens as some saved views reference
            // measures from older data releases
            break;
          }
          if (mObj.lvl <= 1) {
            view.meas.push(measure);
            break;
          }
          measure = mObj.ps_id;
        }
      });

      // unique the measures
      view.meas = [...new Set(view.meas)];

      toJson[key] = view;
    });


    resolveLoadingPromise(true);

    output = toJson;
    return toJson;

  }
};
