import { ChartjsTooltip } from '../../../../components';
import {
  extractSubjectData,
  getSelectedLocationName,
  getSelectedSubjectName,
  getAverageValue,
  getSubjectColor,
  wordWrap, getCountriesInGroup,
} from '../../../../actions';

import {baseFont, defaultVariables} from '../../../../referenceData';
import i18n from "../../../../i18n";

const getDataForTooltip = (selectedLocations, selectedSubject, state) => {
  const body = {};
  const footer = ['African average'];

  selectedLocations.forEach((location) => {
    body[getSelectedLocationName(state, location)] = {
      abr: selectedSubject,
      label: i18n.t("{{subject}} for {{location}}", {
        subject: getSelectedSubjectName(state,selectedSubject),
        location: getSelectedLocationName(state, location)
      })
    };
  });

  return {
    body,
    footer,
  };
};

export const getChartTitle = (state) => {
  const subject = state.selectedSubject;
  const lastYear = defaultVariables.latest_year;
  const barTitle = getSelectedSubjectName(state, subject);

  return window.innerWidth < 480
    ? [barTitle, `- ${i18n.t('Score/Rank')} ${lastYear}`]// eslint-disable-line i18next/no-literal-string
    : [`${barTitle} - ${i18n.t('Score/Rank')} ${lastYear}`];// eslint-disable-line i18next/no-literal-string
};
export const getChartConfig = (state, barTextTitle) => {
  const subject = state.selectedSubject;
  const { selectedLocation } = state;
  const firstYear = defaultVariables.earliest_year;
  const lastYear = defaultVariables.latest_year;
  const groupCountries = getCountriesInGroup(state, selectedLocation);

  const measureData = extractSubjectData({
    state,
    subject,
    firstYear,
    lastYear,
  });
  const measureValues = Object.values(measureData[lastYear]).map((e) => Number(e.v).toFixed(1));
  const averageValues = Array(measureValues.length).fill(
    getAverageValue(state.indexedYml[lastYear][subject]),
  );

  const labelOffsetValue = window.innerWidth > 768 ? -8 : 0;

  const wrap = window.innerWidth < 400 ? 20 : 30;
  const fontSize = window.innerWidth < 400 ? 9 : 12;
  const allLocations = Object.values(measureData[lastYear]).map((e) => e.iso);
  const barChartLabels = allLocations.map((e) => getSelectedLocationName(state, e));
  const wrappedBarDataLabels = wordWrap(barChartLabels, wrap);

  const tooltipData = getDataForTooltip(allLocations, subject, state);

  const groupIsos = groupCountries.map((e) => e.iso);
  const backgroundColors = Object.values(measureData[lastYear]).map((e) => ( groupIsos.indexOf(e.iso) !== -1
    ? `${getSubjectColor({ state, subject })}`
    : `${getSubjectColor({ state, subject })}44`));

  const hoverbackgroundColors = Object.values(measureData[lastYear]).map((e) => ( groupIsos.indexOf(e.iso) !== -1
    ? `${getSubjectColor({ state, subject, sec: true })}`
    : `${getSubjectColor({ state, subject, sec: true })}44`));

  const barData = {
    labels: wrappedBarDataLabels,
    datasets: [
      {
        label: getSelectedSubjectName(state, subject),
        backgroundColor: backgroundColors,
        hoverBackgroundColor: hoverbackgroundColors,
        borderColor: 'transparent',
        borderWidth: 0,
        data: measureValues,
        tooltip: {
          order: 1,
        },
      },
      {
        label: i18n.t('African average'),
        type: 'line',
        borderColor: '#9C9C9C',
        borderDash: [3, 3],
        pointRadius: 0,
        borderWidth: 1,
        data: averageValues,
        pointStyle: 'none',
        tooltip: {
          divider: true,
          order: 11,
        },
      },
    ],
  };

  const indexAxis = window.innerWidth < 768 ? 'y' : 'x';
  const otherAxis = indexAxis === 'y' ? 'x' : 'y';

  return {
    type: 'bar',
    data: barData,
    options: {
      indexAxis,
      interaction: {
        mode: 'index',
        interaction: false,
        axis: indexAxis,
      },
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          left: 0,
          right: 0,
          bottom: 35,
          top: 30,
        },
      },
      scales: {
        [indexAxis]: {
          ticks: {
            autoSkip: false,
            labelOffset: labelOffsetValue,
            barThickness: 30,
            font: {
              size: fontSize,
              lineHeight: 0.9,
            },
          },
        },
        [otherAxis]: {
          min: 0,
          max: 100,
          ticks: {
            stepSize: 10,
            callback: (e) => e.toFixed(1),
          },
        },
      },
      plugins: {
        title: {
          display: true,
          text: barTextTitle,
          font: {
            size: 16,
            family: baseFont(),
            weight: 500,
          },
          color: '#000',
          padding: {
            bottom: 30,
          },
        },
        legend: {
          display: true,
          position: 'bottom',
          onClick: (e, legendItem, legend) => {}, // Disable legend toggling
          labels: {
            usePointStyle: true,
            generateLabels(chart) {
              const { datasets } = chart.data;
              const {
                labels: { textAlign, color },
              } = chart.legend.options;

              const labels = chart._getSortedDatasetMetas().map((meta) => {
                const style = { ...meta.controller.getStyle(meta.index) };
                if (meta.index === 1) {
                  style.borderDash = [3, 3];
                  style.pointStyle = 'line';
                }

                return {
                  text: datasets[meta.index].label,
                  fillStyle: getSubjectColor({ state, subject }) + '44',
                  fontColor: color,
                  hidden: !meta.visible,
                  lineCap: style.borderCapStyle,
                  lineDash: style.borderDash,
                  lineDashOffset: style.borderDashOffset,
                  lineJoin: style.borderJoinStyle,
                  lineWidth: 2,
                  strokeStyle: style.borderColor,
                  pointStyle: style.pointStyle,
                  rotation: style.rotation,
                  textAlign: textAlign || style.textAlign,
                  borderRadius: 0, // TODO: v4, default to style.borderRadius
                  // Below is extra data used for toggling the datasets
                  datasetIndex: meta.index,
                };
              }, this);

              const lastLabel = labels.pop();
              labels.push({
                text: getSelectedLocationName(state, selectedLocation),
                fillStyle: getSubjectColor({ state, subject }),
                fontColor: "#666",
                hidden: false,
                lineWidth: 2,
                strokeStyle: "transparent",
                borderRadius: 0,
                datasetIndex: 0
              });
              labels.push(lastLabel);
              return labels;
            },

          },
        },
        tooltip: {
          enabled: false,
          external: (context) => {
            ChartjsTooltip({
              state,
              context,
              tooltipData,
              hideComparison: true,
              activeDatasets: [],
            });
          },
        },
      },
    },
  };
};
