import {
  getAverageValue,
  getSelectedLocationName,
  getSelectedSubjectName,
  getSubjectColor,
  prettifyNumber,
  getItemsWithParent,
  roundToOne, getAvgScoreForGroup,
} from '../../../../actions';
import { ChartjsTooltip } from '../../../../components';
import {baseFont, defaultVariables} from '../../../../referenceData';
import i18n from "../../../../i18n";

const getDataForTooltip = (subjects, selectedLocation, state) => {
  const body = {};
  const footer = [i18n.t('African average')];

  Object.keys(subjects).forEach((subject) => {
    body[subject] = {
      abr: subjects[subject],
      label: `${getSelectedLocationName(state, selectedLocation)}:`,
    };
  });

  return {
    body,
    footer,
  };
};

const createVerticalDash = () => {
  const canvas = document.createElement('canvas');
  canvas.width = 10; // Adjust width of the point
  canvas.height = 30; // Adjust height of the point
  const ctx = canvas.getContext('2d');

  // Draw a vertical dashed line
  ctx.beginPath();
  ctx.setLineDash([5, 5]); // 5px dash, 5px gap
  ctx.moveTo(canvas.width / 2, 4); // Start in the middle top
  ctx.lineTo(canvas.width / 2, canvas.height-4); // Draw to the bottom
  ctx.lineWidth = 1; // Adjust thickness
  ctx.strokeStyle = '#9C9C9C'; // Adjust color
  ctx.stroke();

  return canvas;
};

export const getChartTitle = (state) => {
  const lastYear = defaultVariables.latest_year;
  const { selectedLocation } = state;

  return window.innerWidth < 480
    ? [
      getSelectedLocationName(state, selectedLocation),
      i18n.t("Sub-Category Scores - {{year}}", { year: lastYear }),
    ]
    : [
      `${getSelectedLocationName( // eslint-disable-line i18next/no-literal-string
        state,
        selectedLocation,
      )} - ${i18n.t("Sub-Category Scores - {{year}}", { year: lastYear })}`,
    ];
};

export const getChartConfig = (state, locationTitleText) => {
  const { selectedLocation } = state;
  const lastYear = defaultVariables.latest_year;

  const labels = [];
  const values = {};
  const padding = [];
  const endpadding = [];
  const avgs = [];
  const mapped12MeasuresWithSubjects = {};

  getItemsWithParent(state, 'GOVERNANCE').forEach((meas) => {
    getItemsWithParent(state, meas.s_id).forEach((l2meas) => {
      labels.push(getSelectedSubjectName(state, l2meas.s_id));
      values[l2meas.ps_id] = values[l2meas.ps_id] || [];
      const val = getAvgScoreForGroup(state, l2meas.s_id, lastYear, selectedLocation);
      values[l2meas.ps_id].push(
        val > -1
          ? roundToOne(
            val
          )
          : null,
      );
      endpadding.push(null);
      mapped12MeasuresWithSubjects[getSelectedSubjectName(state, l2meas.s_id)] = meas.s_id;
      avgs.push(getAverageValue(state.indexedYml[lastYear][l2meas.s_id]));
    });
  });

  const colors = [];
  const lightColors = [];
  const datasets = [];

  const tooltipData = getDataForTooltip(
    mapped12MeasuresWithSubjects,
    selectedLocation,
    state,
  );

  Object.keys(values).forEach((subjectName) => {
    const thisValues = [...padding];
    values[subjectName].forEach((subitem) => {
      thisValues.push(parseFloat(subitem));
      padding.push(null);
      endpadding.pop();
      colors.push(getSubjectColor({ state, subject: subjectName }));
      lightColors.push(`${getSubjectColor({ state, subject: subjectName })}33`);
    });
    thisValues.push(...endpadding);
    datasets.push({
      label: getSelectedSubjectName(state, subjectName),
      borderColor: 'transparent',
      pointColor: getSubjectColor({ state, subject: subjectName }),
      backgroundColor: `${getSubjectColor({ state, subject: subjectName })}99`,
      data: thisValues,
      skip: true,
      tooltip: {
        order: 1,
        callbacks: {
          label(ctx) {
            return `${getSelectedLocationName( // eslint-disable-line i18next/no-literal-string
              state,
              selectedLocation,
            )}: ${prettifyNumber(roundToOne(ctx.formattedValue))}`;
          },
        },
      },
    });
  });

  datasets.push({
    label: i18n.t('African average'),
    borderColor: '#9C9C9C',
    borderDash: [3, 3],
    borderWidth: 1,
    pointBackgroundColor: '#9C9C9C',
    pointRadius: 2,
    backgroundColor: 'transparent',
    data: avgs,
    tooltip: {
      order: 11,
      divider: true,
      titleSource: true,
      titleLabels: labels,
      callbacks: {
        labelPointStyle: () => ({
          pointStyle: 'none',
          borderDash: [3, 3],
        }),
      },
    },
  });

  return {
    type: 'radar',
    data: {
      labels,
      datasets,
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          left: 0,
          right: 0,
          bottom: 35,
          top: 30,
        },
      },
      interaction: {
        mode: 'index',
      },
      scales: {
        r: {
          grid: {
            circular: true,
            display: true,
          },
          beginAtZero: true,
          startAngle: 360 / (colors.length * 2),
          min: 0,
          max: 100,
          ticks: {
            display: false,
            // stepSize: 25,
            // maxTicksLimit: 50
          },
          angleLines: {
            display: true,
            color: lightColors,
            borderDash: [5, 5],
            lineWidth: 2,
          },
          pointLabels: {
            display: window.innerWidth > 640,
            color: colors,
            font: {
              size: 12,
            },
          },
        },
      },
      plugins: {
        title: {
          display: true,
          text: locationTitleText,
          font: {
            size: 16,
            family: baseFont(),
            weight: 500,
          },
          color: '#000',
          padding: {
            bottom: 30,
          },
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            usePointStyle: true,
            padding: 15,
            generateLabels: (chart) => {
              const datasets = chart.data.datasets;
              return datasets.map((dataset, i) => {
                return {
                text: dataset.label ,
                fillStyle: dataset.pointColor,
                strokeStyle: dataset.pointColor,
                lineWidth: dataset.borderWidth,
                pointStyle: i == 4 ? createVerticalDash() : 'rect', // Assign different pointStyle
                datasetIndex: i,
              }});
            }
          }
        },
        tooltip: {
          enabled: false,
          external: (context) => {
            ChartjsTooltip({
              state,
              context,
              tooltipData,
              activeDatasets: [],
              hideComparison: true,
            });
          },
        },
      },
    },
  };
};
