import React from "react";
import { useAppContext } from "../../context/AppState.jsx";
import { LocationPickerMenu } from "../../components";
import { getSelectedLocations, getSelectedLocationName } from "../../actions";
import { setUrlQueryParam, setMenuToOpen } from "../../actions";
import i18n from "../../i18n";

const getDescription = (isSimplifiedView) =>
	isSimplifiedView
		? i18n.t("Please select a country to find out more about it.")
		: i18n.t("Please select your location(s) below. You can choose single or multiple countries or groups of countries. Please note, it is not possible to compare multiple countries when you have more than one measure selected.");

export default ({ isSimplifiedView = false }) => {
	const [state, dispatch] = useAppContext();

	const show = state.setMenuToOpen === "location";

	const fullListOfCountries = state?.fullListOfCountries || [];

	const description = getDescription(isSimplifiedView);

	const countryList = fullListOfCountries
		.map((country) => ({
			iso: country.iso,
			fullName: getSelectedLocationName(state, country.iso),
		}));


	const fullListOfCountryGroups = state?.fullListOfCountryGroups || [];

	const shortGroupList = fullListOfCountryGroups.filter(e => e.rec || e.sh_nm === 'Africa').map((group) => ({
		uid: group.uid,
		fullName: getSelectedLocationName(state, group.uid),
		sh_nm: group.sh_nm,
		rec: group.rec,
	}));

	if (isSimplifiedView) {
		return (
			<LocationPickerMenu
				description={description}
				isSimplifiedView={isSimplifiedView}
				countryList={countryList}
				groupList={shortGroupList}
			/>
		);
	}

	const selectedLocations = getSelectedLocations(state);

	const groupList = fullListOfCountryGroups.map((group) => ({
		uid: group.uid,
		fullName: getSelectedLocationName(state, group.uid),
	}));

	const onLocationSelect = (selectedLocations) =>
		dispatch({
			type: "setUrlQueryParam",
			payload: setUrlQueryParam({ name: "loc", value: selectedLocations }),
		});

	const buttons = [
		{
			label: i18n.t("Clear Selection"),
			onClick: () => {
				dispatch({
					type: "setUrlQueryParam",
					payload: setUrlQueryParam({ name: "loc", value: "" }),
				});
			},
			class: "clear-comparison",
		},
		{
			label: i18n.t("Show Comparison"),
			onClick: () => {
				dispatch({
					type: "setMenuToOpen",
					payload: setMenuToOpen(""),
				});
			},
			class: "show-comparison",
		},
	];

	const onAllCountriesSelect = (isSelected) => {
		const listOfAllCountries = countryList.map((country) => country.iso);

		if (!isSelected) {
			const mergegedSelectedLocations = [...new Set([...listOfAllCountries, ...selectedLocations])];

			dispatch({
				type: "setUrlQueryParam",
				payload: setUrlQueryParam({
					name: "loc",
					value: mergegedSelectedLocations.join("-"),
				}),
			});
		} else {
			const removedCountriesFromSelectedLocations = selectedLocations.filter(
				(location) => !listOfAllCountries.includes(location)
			);

			dispatch({
				type: "setUrlQueryParam",
				payload: setUrlQueryParam({ name: "loc", value: removedCountriesFromSelectedLocations.join("-") }),
			});
		}
	};

	const onAllGroupsSelect = (isSelected) => {
		const listOfAllGroups = groupList.map((group) => group.uid);

		if (!isSelected) {
			const mergegedSelectedLocations = [...new Set([...listOfAllGroups, ...selectedLocations])];

			dispatch({
				type: "setUrlQueryParam",
				payload: setUrlQueryParam({ name: "loc", value: mergegedSelectedLocations.join("-") }),
			});
		} else {
			const removedCountriesFromSelectedLocations = selectedLocations.filter(
				(location) => !listOfAllGroups.includes(location)
			);

			dispatch({
				type: "setUrlQueryParam",
				payload: setUrlQueryParam({ name: "loc", value: removedCountriesFromSelectedLocations.join("-") }),
			});
		}
	};

	return (
		<LocationPickerMenu
			show={show}
			description={description}
			isSimplifiedView={isSimplifiedView}
			countryList={countryList}
			groupList={groupList}
			selectedLocations={selectedLocations}
			onLocationSelect={onLocationSelect}
			buttons={buttons}
			onAllCountriesSelect={onAllCountriesSelect}
			onAllGroupsSelect={onAllGroupsSelect}
		/>
	);
};
