export default (vals) => {
  const validValues = Object.values(vals)
    .map((e) => e.v)
    .filter((value) => !isNaN(value) && value != -1)
    .map(Number); // Remove NaN values

  console.log(validValues);
  if (validValues.length === 0) {
    return 0; // Return 0 if there are no valid values
  }

  const average = validValues.reduce((sum, value) => sum + value, 0) / validValues.length;
console.log(average);
  return Number(average.toFixed(1)); // Format to 1 decimal place
};
