import {
  getAverageValue,
  getSelectedLocationName,
  getSelectedSubjectName,
  getSubjectColor,
  wordWrap,
  getItemsWithParent,
  isCitvoiceMeasure, getAvgScoreForGroup,
} from '../../../../actions';
import { ChartjsTooltip } from '../../../../components';
import {baseFont, defaultVariables} from '../../../../referenceData';
import i18n from "../../../../i18n";


const getDataForTooltip = (selectedLocation, subjects, state) => {
  const body = {};
  const footer = [i18n.t('African average'), i18n.t('Range of scores')];

  subjects.forEach((subject) => {
    body[getSelectedSubjectName(state, subject)] = {
      abr: subject,
      label: `${getSelectedLocationName(state, selectedLocation)}:`,
    };
  });

  return {
    body,
    footer,
  };
};

const getItem = (state, s_id) => state.fullListOfMeasures.filter((n) => n.s_id === s_id);

export const getChartTitle = (state) => {
  const lastYear = defaultVariables.latest_year;
  const { selectedLocation } = state;

  return window.innerWidth < 480
    ? [
      getSelectedLocationName(state, selectedLocation),
      i18n.t("Category Scores - {{year}}", { year: lastYear }),
    ]
    : [
      `${getSelectedLocationName(// eslint-disable-line i18next/no-literal-string
        state,
        selectedLocation,
      )} - ${i18n.t("Category Scores - {{year}}", { year: lastYear })}`,
    ];
};

export const getChartConfig = (state, locationTitleText) => {
  const { selectedLocation } = state;
  const lastYear = defaultVariables.latest_year;

  const minMaxs = {};
  let avgs = [];
  let thisVals = [];
  const labels = [];
  const subjectAbbriviations = [];
  const colors = [];

  getItemsWithParent(state, 'GOVERNANCE').forEach((meas) => {
    const el = state.indexedYml[lastYear][meas.s_id];

    const item = getItem(state, meas.s_id)[0];

    // const thismeasure = el[selectedLocation];

    const score = getAvgScoreForGroup(state, meas.s_id, latest_year, selectedLocation);



    if (item.lvl === 1 && !isCitvoiceMeasure(state, item.s_id)) {
      const vals = Object.values(el)
        .filter((e) => e.v >= 0)
        .sort((e1, e2) => e2.v - e1.v);
      minMaxs[item.s_id] = [vals[0], vals[vals.length - 1]];
      subjectAbbriviations.push(item.s_id);
      labels.push(getSelectedSubjectName(state, item.s_id));
      colors.push(getSubjectColor({ state, subject: item.s_id }));
      thisVals.push(Number(score).toFixed(1));
      avgs.push(getAverageValue(vals));
    }
  });
  thisVals = thisVals.map((e, i) => [e, i + 0.2]);
  avgs = avgs.map((e, i) => [e, i + 0.5]);

  const wrap = window.innerWidth < 400 ? 10 : 20;
  const wrappedLabels = wordWrap(labels, wrap);

  const pointCanvas = document.createElement('canvas');
  pointCanvas.width = 65;
  pointCanvas.height = 2;
  const pointCtx = pointCanvas.getContext('2d');

  // Dashed line
  pointCtx.beginPath();
  pointCtx.setLineDash([4, 2]);
  pointCtx.moveTo(0, 1);
  pointCtx.lineTo(65, 1);
  pointCtx.lineWidth = 2;
  pointCtx.strokeStyle = '#8F8F8F';
  pointCtx.stroke();

  const tooltipData = getDataForTooltip(
    selectedLocation,
    subjectAbbriviations,
    state,
  );

  return {
    // type: 'bar',
    data: {
      datasets: [
        {
          label: getSelectedLocationName(state, selectedLocation),
          backgroundColor: colors.reverse(),
          borderColor: colors.reverse(),
          data: thisVals,
          type: 'scatter',
          yAxisID: 'scatterY',
          radius: 10,
          hoverRadius: 10,
          pointStyle: 'triangle',
          showLine: false,
          tooltip: {
            order: 1,
          },
        },
        {
          label: i18n.t('African average'),
          backgroundColor: '#8F8F8F',
          borderColor: '#8F8F8F',
          data: avgs,
          type: 'scatter',
          yAxisID: 'scatterY',
          pointStyle: pointCanvas,
          rotation: 90,
          showLine: false,
          tooltip: {
            order: 14,
          },
        },
        {
          label: i18n.t('Range of scores'),
          type: 'bar',
          barPercentage: 0.66,
          backgroundColor: colors,
          borderColor: colors,
          data: Object.values(minMaxs).map((e) => [
            Number(e[1].v).toFixed(1),
            Number(e[0].v).toFixed(1),
          ]),
          pointStyle: 'rect',
          tooltip: {
            order: 13,
          },
        },
      ],
    },
    options: {
      interaction: {
        mode: 'index',
        intersect: false,
        axis: 'y',
      },
      indexAxis: 'y',
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          left: 0,
          right: 0,
          bottom: 35,
          top: 30,
        },
      },
      scales: {
        category1: {
          labels: wrappedLabels,
          axis: 'y',
          offset: true,
        },
        scatterY: {
          labels: wrappedLabels,
          axis: 'y',
          offset: false,
          position: 'right',
          min: 0,
          max: 4,
          reverse: true,
          display: false,
        },
        x: {
          min: 0,
          max: 100,
          ticks: {
            callback: (e) => e.toFixed(1),
          },
        },
      },
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            usePointStyle: true,
            generateLabels(chart) {
              // this method is a copy + edit of the one from core chartjs.
              // chartjs updates may need it copy/pasted/rededited.
              // reference https://github.com/chartjs/Chart.js/blob/v3.3.2/src/plugins/plugin.legend.js#L651
              // just added the overrides on if / if/else to allow variation per dataset
              const { datasets } = chart.data;
              const {
                labels: { textAlign, color },
              } = chart.legend.options;

              return chart._getSortedDatasetMetas().map((meta) => {
                const style = { ...meta.controller.getStyle(meta.index) };
                if (meta.index === 1) {
                  style.borderDash = [3, 3];
                  style.pointStyle = 'line';
                }

                return {
                  text: datasets[meta.index].label,
                  fillStyle: '#9C9C9C',
                  fontColor: color,
                  hidden: !meta.visible,
                  lineCap: style.borderCapStyle,
                  lineDash: style.borderDash,
                  lineDashOffset: style.borderDashOffset,
                  lineJoin: style.borderJoinStyle,
                  lineWidth: 2,
                  strokeStyle: '#9C9C9C',
                  pointStyle: style.pointStyle,
                  rotation: style.rotation,
                  textAlign: textAlign || style.textAlign,
                  borderRadius: 0, // TODO: v4, default to style.borderRadius

                  // Below is extra data used for toggling the datasets
                  datasetIndex: meta.index,
                };
              }, this);
            },
            padding: 28,
          },
        },
        tooltip: {
          enabled: false,
          external: (context) => ChartjsTooltip({
            state,
            context,
            tooltipData,
            activeDatasets: [],
            hideComparison: true,
          }),
          callbacks: {
            title: (data) => data[2].chart.config.options.scales.category1.labels[
              data[2].dataIndex
            ].join(' '),
          },
        },
        title: {
          display: true,
          text: locationTitleText,
          font: {
            size: 16,
            family: baseFont(),
            weight: 500,
          },
          color: '#000',
          padding: {
            bottom: 30,
          },
        },
      },
    },
  };
};
