import {
  getCarouselContent,
  getUrlQueryParams,
  readCookie,
  setCookie,
} from './actions';
import { recreateCustomViewsSection } from './lib/savedviews';
import i18n from './i18n';
import * as basicLightbox from 'basiclightbox';

const initializeCarousel = async () => {
  const $carousel = global.$('.saved-carousel-inner');
  if (!$carousel.length) {
    return;
  }

  const $emptySavedItem = global.$(
    `<div class="saved-carousel-item">
      <h3><img class="view-icon" src="" alt="Graph view" /> <span class="view-name"></span></h3>
      <h2></h2>
      <h6></h6>
      <div class="saved-carousel-subject-icons"></div>
      <a href="#" class="btn btn-outline-primary">${i18n.t('View now')}</a>
   </div>`
  );

  const carouselContent = await getCarouselContent();

  carouselContent.forEach((slide) => {
    const $newItem = $emptySavedItem.clone();

    // Set dynamic text content
    $newItem.find('h2').text(slide[`${i18n.language}_name`]);
    $newItem.find('h6').text(slide[`${i18n.language}_description`]);

    // Populate subject icons dynamically if they exist
    const $iconsContainer = $newItem.find('.saved-carousel-subject-icons');
    if (slide.meas) {
      slide.meas.slice(0,10).forEach((meas) => {
        const $icon = global.$(
          `<div class="saved-carousel-subject-icon"><img src="/img/icons/${meas.toUpperCase()}.svg" alt="${meas}" /></div>`
        );
        $iconsContainer.append($icon);
      });
    }

    // Set the link href dynamically
    $newItem.find('a').attr('href', `app.html?v=${slide.uid}`);
    $newItem.find('.view-icon').attr('src', '/img/icons/' + slide.view + '.svg');
    let viewName;
    switch (slide.view) {
      case 'trends':
        viewName = i18n.t('Map').toUpperCase();
        break;
      default:
        viewName = i18n.t(slide.view).toUpperCase();;
    }
    $newItem.find('.view-name').text(viewName);

    // Append the new item to the carousel
    $carousel.append($newItem); // Uncomment and ensure `$carousel` is defined
  });

  // global.$('#savedslider-carousel').carousel();

  const carousel = document.querySelector('.saved-carousel');
  const carouselInner = document.querySelector('.saved-carousel-inner');
  const items = Array.from(document.querySelectorAll('.saved-carousel-item'));
  const prevButton = document.querySelector('.saved-carousel-control-prev');
  const nextButton = document.querySelector('.saved-carousel-control-next');

  if (!carousel || !carouselInner || items.length === 0 || !prevButton || !nextButton) {
    console.error('Carousel setup is missing required elements.');
    return;
  }

  // Function to determine the number of visible items based on CSS media queries
  function calculateVisibleItems() {
    const width = window.innerWidth;
    if (width >= 992) return 3; // Large screens
    if (width >= 768) return 2; // Medium screens
    return 1; // Small screens
  }

  setupControls();

  let currentIndex; // Index to track the current position
  let autoScrollInterval;
  let visibleItems; // Number of items visible at a time
  let itemWidth; // Width of each item including gap

  // Function to set up clones for infinite looping
  function setupClones() {
    visibleItems = calculateVisibleItems();
    itemWidth = items[0].offsetWidth + 20; // Include gap between items
    currentIndex = visibleItems;

    // Remove any existing clones
    const clones = carouselInner.querySelectorAll('.clone');
    clones.forEach(clone => clone.remove());

    // Clone the required number of items
    for (let i = 0; i < visibleItems; i++) {
      const firstClone = items[i].cloneNode(true);
      const lastClone = items[items.length - 1 - i].cloneNode(true);
      firstClone.classList.add('clone');
      lastClone.classList.add('clone');
      carouselInner.appendChild(firstClone); // Append clones to the end
      carouselInner.insertBefore(lastClone, items[0]); // Prepend clones to the start
    }

    // Update the total width of carouselInner
    // const totalItems = items.length + 2 * visibleItems; // Real items + clones
    // carouselInner.style.width = `${totalItems * itemWidth}px`;

    // Position carousel at the first "real" set of items
    carouselInner.style.transition = 'none'; // Disable animation
    carouselInner.style.transform = `translateX(-${currentIndex * itemWidth}px)`;
  }

  // Scroll to the current item with smooth animation
  function scrollToItem(index) {
    currentIndex = index;

    // Apply the scroll with animation
    carouselInner.style.transition = 'transform 0.3s ease';
    carouselInner.style.transform = `translateX(-${currentIndex * itemWidth}px)`;

    // Handle looping logic after the animation ends
    carouselInner.addEventListener('transitionend', function handleLoop() {
      if (currentIndex < visibleItems) {
        // Loop back to the last real set of items
        carouselInner.style.transition = 'none'; // Disable animation
        currentIndex = items.length + (currentIndex - visibleItems);
        carouselInner.style.transform = `translateX(-${currentIndex * itemWidth}px)`;
      } else if (currentIndex >= items.length + visibleItems) {
        // Loop back to the first real set of items
        carouselInner.style.transition = 'none'; // Disable animation
        currentIndex = currentIndex - items.length;
        carouselInner.style.transform = `translateX(-${currentIndex * itemWidth}px)`;
      }
      carouselInner.removeEventListener('transitionend', handleLoop);
    });
  }

  function hideControls() {
    prevButton.style.display = 'none';
    nextButton.style.display = 'none';
  }

  function showControls() {
    prevButton.style.display = 'block';
    nextButton.style.display = 'block';
  }

  function setupControls() {
    if (items.length <= calculateVisibleItems()) {
      hideControls();
    } else {
      showControls();
    }
  }

  // Event listeners for buttons
  prevButton.addEventListener('click', function (e) {
    e.preventDefault();
    scrollToItem(currentIndex - 1);
    resetAutoScroll(); // Reset auto-scroll timer
  });

  nextButton.addEventListener('click', function (e) {
    e.preventDefault();
    scrollToItem(currentIndex + 1);
    resetAutoScroll(); // Reset auto-scroll timer
  });

  // Auto-scroll every 5 seconds
  function startAutoScroll() {
    if (items.length <= calculateVisibleItems()) return; // No need to scroll
    console.log('scroll');
    autoScrollInterval = setInterval(() => {
      scrollToItem(currentIndex + 1);
    }, 5000);
  }



  function stopAutoScroll() {
    clearInterval(autoScrollInterval);
  }

  function resetAutoScroll() {
    stopAutoScroll();
    startAutoScroll();
  }

  startAutoScroll(); // Start auto-scroll on load

  // Touch support
  let startX = 0;
  let isDragging = false;

  carouselInner.addEventListener('touchstart', function (e) {
    stopAutoScroll(); // Pause auto-scroll during touch interaction
    startX = e.touches[0].clientX;
    isDragging = true;
  });

  carouselInner.addEventListener('touchmove', function (e) {
    if (!isDragging) return;
    const touchX = e.touches[0].clientX;
    const moveX = touchX - startX;

    // Threshold to determine a swipe
    if (Math.abs(moveX) > 50) {
      if (moveX > 0) {
        scrollToItem(currentIndex - 1); // Swipe right
      } else {
        scrollToItem(currentIndex + 1); // Swipe left
      }
      isDragging = false; // Prevent multiple triggers
      resetAutoScroll();
    }
  });

  carouselInner.addEventListener('touchend', function () {
    isDragging = false;
    resetAutoScroll(); // Resume auto-scroll after touch ends
  });

  // Handle window resize
  window.addEventListener('resize', function () {
    setupControls(); // Show/hide controls based on item count
    setupClones(); // Recalculate clones and positions
    resetAutoScroll(); // Reset auto-scroll timer
  });

  // Initial setup
  setupClones();



  const $curatedList = global.$('#lstCuratedViews');

  if ($curatedList.length) {
    carouselContent.forEach((slide) => {
      let div = `<a href="app.html?v=${slide.uid}" class="">`;  // eslint-disable-line i18next/no-literal-string
      div += `<h5>${slide[`${i18n.language}_name`]}</h5>`; // eslint-disable-line i18next/no-literal-string
      div += `<p> ${slide[`${i18n.language}_description`]}</p>`;// eslint-disable-line i18next/no-literal-string

      div += '</a>';

      $curatedList.append(div);
    });
  }
};

const acceptCookies = () => {
  Array.from(document.getElementsByClassName('cookiealert')).forEach((e) => e.remove());
  setCookie('acceptCookies', true, 365);
};

const dismissSwn = () => {
  Array.from(document.getElementsByClassName('idp-sitewide-notice')).forEach(
    (e) => e.remove(),
  );
  setCookie('sitewide-notice-dismissed', true, 365);
};

const autoAcceptCookiesDismissSwnForKiosk = () => {
  const url = new URL(window.location);
  const { cookie_accept: cookieAccept, swn_dismiss: swnDismiss } = getUrlQueryParams();

  if (typeof cookieAccept !== 'undefined' && JSON.parse(cookieAccept) === 1) {
    acceptCookies();
  }

  if (typeof swnDismiss !== 'undefined' && JSON.parse(swnDismiss) === 1) {
    dismissSwn();
  }

  if (url.pathname === '/embed.html') {
    if (
      readCookie('acceptCookies')
      || typeof cookieAccept === 'undefined'
      || JSON.parse(cookieAccept) === 1
    ) {
      acceptCookies();
    }
  }
};

const videoToggler = () => {
  global.$('.how-video').addClass('d-none');
  const $target = global.$(
    '#how-accordion .card-header button:not(.collapsed)',
  );
  if ($target.length) {
    global.$(`#${$target.attr('rel')}`).removeClass('d-none');
  } else {
    global.$('#overview_img').removeClass('d-none');
  }
};

const imageToggler = () => {
  global.$('.overview-img').addClass('d-none');
  const $target = global.$(
    '#overview-accordion .card-header button:not(.collapsed)',
  );
  if ($target.length) {
    global.$('#overview-accordion-reset').removeClass('active');
    global.$(`#${$target.attr('rel')}`).removeClass('d-none');
  } else {
    global.$('#overview-accordion-reset').addClass('active');
    global.$('#overview_img').removeClass('d-none');
  }
};

const additionalLayoutCorrections = () => {
  // used in copyright
  global.$('.current_year').text(new Date().getUTCFullYear());

  global.$('#how-accordion').on('hidden.bs.collapse', videoToggler);
  global.$('#how-accordion').on('shown.bs.collapse', videoToggler);

  global.$('#overview-accordion').on('hidden.bs.collapse', imageToggler);
  global.$('#overview-accordion').on('shown.bs.collapse', imageToggler);
  global.$('#overview-accordion-reset').on('click', (e) => {
    e.preventDefault();
    global.$('#overview-accordion .card-header button').addClass('collapsed');
    global.$('#overview-accordion .collapse').removeClass('show');
    imageToggler();
  });

  global.$('.country-menu').on('beforeshow', () => {
    global.$('#subjectMenu').attr('hidden', true);
    global.$('#viewMenu').attr('hidden', true);
  });

  global.$('.view-menu').on('beforeshow', () => {
    global.$('#subjectMenu').attr('hidden', true);
    global.$('#countryMenu').attr('hidden', true);
  });

  global.$('.measure-menu').on('beforeshow', () => {
    global.$('#countryMenu').attr('hidden', true);
    global.$('#viewMenu').attr('hidden', true);
  });
};

const lightboxes = () => {

  // eslint-disable-next-line i18next/no-literal-string
  const lightbox = basicLightbox.create(`
    <div class="lb-iframe-wrap">
        <iframe class="lb-iframe" title="vimeo-player" src="https://player.vimeo.com/video/${i18n.t('about_video')}?h=dbbde8f8cb"  frameborder="0"    allowfullscreen></iframe>
    </div>
    `);

  // document.querySelector('.hero-video-launch')?.addEventListener('click', () => {
  //   lightbox.show();
  // });

}

const menuTouchClickFallbacks = () => {
  document.querySelectorAll('.primary-nav a > span').forEach((e) => {
    e.addEventListener('click', (event) => {
      event.preventDefault();
      // toggle open class to nearest a tag
        e.closest('li').classList.toggle('open');


    });
  });
}

export default async () => {
  autoAcceptCookiesDismissSwnForKiosk();
  menuTouchClickFallbacks();

  if (
    window.location.pathname !== '/data.html'
    && window.location.pathname !== '/embed.html'
  ) {
    await initializeCarousel();
    recreateCustomViewsSection();
    additionalLayoutCorrections();
    lightboxes();

  }

  if (window.location.pathname === '/data.html') {
    // eslint-disable-next-line no-undef
    await initializeCarousel();
  }
};
