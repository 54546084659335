import React from "react";
import styles from "./styles.css";

export default ({ children }) => {
	document.getElementById('Footer')?.remove();
	document.querySelectorAll('.saved-slider-wrapper').forEach(element => element.remove());
	
	return (
		<div id="screenshotPdfFrame" className={styles.screenshotFrame}>
			{children}
		</div>
	);
};
