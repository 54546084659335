import React, { useState, useEffect, useRef } from "react";
import styles from "./styles.css";
import { default as LoadingSpinner } from "../LoadingSpinner/index.jsx";
import { useTranslation } from "react-i18next";


/**
 * I decided not to fix this / maintain twice and added the static footer to the data page instead
 * @param slides
 * @returns {JSX.Element}
 * @constructor
 */

const SavedCarousel = ({ slides }) => {
	const { t } = useTranslation();
	const carouselRef = useRef(null);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [visibleItems, setVisibleItems] = useState(1);
	const [itemWidth, setItemWidth] = useState(0);

	// Calculate visible items based on screen size
	const calculateVisibleItems = () => {
		const width = window.innerWidth;
		if (width >= 992) return 3;
		if (width >= 768) return 2;
		return 1;
	};

	// Update dimensions on load and resize
	useEffect(() => {
		const updateDimensions = () => {
			const visible = calculateVisibleItems();
			setVisibleItems(visible);
			const items = carouselRef.current?.querySelectorAll(".saved-carousel-item");
			if (items?.length > 0) {
				setItemWidth(items[0].offsetWidth);
			}
		};

		updateDimensions();
		window.addEventListener("resize", updateDimensions);
		return () => window.removeEventListener("resize", updateDimensions);
	}, []);

	// Auto-scroll logic
	useEffect(() => {
		const autoScroll = setInterval(() => {
			handleNext();
		}, 5000);

		return () => clearInterval(autoScroll); // Cleanup on unmount
	}, [currentIndex]);

	const handleNext = () => {
		const totalItems = slides.length;
		const nextIndex = (currentIndex + 1) % totalItems;
		setCurrentIndex(nextIndex);
	};

	const handlePrev = () => {
		const totalItems = slides.length;
		const prevIndex = (currentIndex - 1 + totalItems) % totalItems;
		setCurrentIndex(prevIndex);
	};

	// Touch gesture support
	let startX = 0;
	let isDragging = false;

	const handleTouchStart = (e) => {
		startX = e.touches[0].clientX;
		isDragging = true;
	};

	const handleTouchMove = (e) => {
		if (!isDragging) return;
		const moveX = e.touches[0].clientX - startX;
		if (Math.abs(moveX) > 50) {
			if (moveX > 0) {
				handlePrev();
			} else {
				handleNext();
			}
			isDragging = false;
		}
	};

	const handleTouchEnd = () => {
		isDragging = false;
	};

	return (
		<div className="saved-slider-wrapper" dir="ltr">
			<div className="uk-container">
				<div
					className="saved-slider uk-flex uk-flex-wrap uk-margin-remove uk-padding-small"
					id="saved-slider"
					ref={carouselRef}
					onTouchStart={handleTouchStart}
					onTouchMove={handleTouchMove}
					onTouchEnd={handleTouchEnd}
				>
					{/*<button*/}
					{/*	className={`saved-carousel-control-prev ${styles.previousArrow}`}*/}
					{/*	onClick={handlePrev}*/}
					{/*>*/}
					{/*	{t("Previous")}*/}
					{/*</button>*/}
					<div
						className="saved-carousel-inner"
						style={{
							display: "flex",
							transform: `translateX(-${currentIndex * (100 / visibleItems)}%)`,
							transition: "transform 0.3s ease",
						}}
					>
						{slides.map((slide, index) => (
							<div
								className="saved-carousel-item"
								key={index}
								style={{ flex: `0 0 ${100 / visibleItems}%` }}
							>
								<h3>
									<img
										className="view-icon"
										src={slide.viewIcon}
										alt={slide.viewName}
									/>
									<span className="view-name">{slide.viewName}</span>
								</h3>
								<h2>{slide.title}</h2>
								<h6>{slide.description}</h6>
								<div className="saved-carousel-subject-icons">
									{slide.measIcons.map((icon, i) => (
										<div className="saved-carousel-subject-icon" key={i}>
											<img src={icon.src} alt={icon.alt} />
										</div>
									))}
								</div>
								<a
									className={`btn btn-outline-primary ${styles.viewButton}`}
									href={slide.link}
								>
									{t("View now")}
								</a>
							</div>
						))}
					</div>
					{/*<button*/}
					{/*	className={`saved-carousel-control-next ${styles.nextArrow}`}*/}
					{/*	onClick={handleNext}*/}
					{/*>*/}
					{/*	{t("Next")}*/}
					{/*</button>*/}
				</div>
			</div>
		</div>
	);
};

export default SavedCarousel;
