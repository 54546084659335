import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.jsx';

export default () => {
  const rootElement = ReactDOM.createRoot(
    document.getElementById('root_element_group_page'),
  );

  rootElement.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
};
