import 'bootstrap';
import 'jquery';
import feather from 'feather-icons';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import * as Datamap from 'datamaps/dist/datamaps.all';
import '../scss/index.scss';
import * as savedviews from './lib/savedviews';
import 'd3-geo';
import 'ion-rangeslider';
import 'jquery.easing';
import 'nvd3';
import { idpevent } from './lib/event';
import 'bootstrap-cookie-alert/cookiealert';
import {
  DataPage,
  EmbedPage, GroupDetailedInfo,
  LocationDetailedInfo,
  SubjectDetailedInfo,
} from './pages';
import * as Commands from './actions';
import * as Providers from './actions';
import initializeApp from './utils';
import i18n from './i18n';
import { registerServiceWorker } from './actions';

import './i18n';

feather.replace({ width: '1em', height: '1em' });

// these are here to ensure that the legacy code
// has active to the newer/dependency managed versions of these libs
// rather than the older bunded in versions
// remove if / when the legacy code gets converted to proper modules
window.$ = jQuery;
window.UIkit = UIkit;

UIkit.use(Icons);

window.d3 = d3;
window.Datamap = Datamap;
window.savedviews = savedviews;
window.idpevent = idpevent;
window.DataPage = DataPage;
window.EmbedPage = EmbedPage;
window.Commands = Commands;
window.Providers = Providers;
window.i18n = i18n;

let runJquery = true;

if (window.location.pathname === '/data.html') {
  runJquery = true;
  // const run = async () => {
  //   await registerServiceWorker();
  // };
  // run();
  DataPage();
}

if (window.location.pathname === '/embed.html') {
  // needed for swn dismiss and cookie accept
  runJquery = true;
  // const run = async () => {
  //   await registerServiceWorker();
  // };
  // run();
  EmbedPage();
}

if (/\/locations\//.test(window.location.pathname)) {
  runJquery = true;
  // const run = async () => {
  //   await registerServiceWorker();
  // };
  // run();
  if (document.querySelector('.group-page')) {
    GroupDetailedInfo();
  } else {
    LocationDetailedInfo();
  }
}

if (/\/measures\//.test(window.location.pathname)) {
  runJquery = true;
  // const run = async () => {
  //   await registerServiceWorker();
  // };
  // run();
  SubjectDetailedInfo();
}

if (runJquery) {
  // on load
  window.addEventListener('load', () => {
    initializeApp();
  });
}
